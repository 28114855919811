import React from "react";
import styles from "./styles.module.css";

export default function AProposDeNebs() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Politique d'accessibilité du site Web</h1>
        <div class="privacyQuestion first reasons-content2 ">
          <p>
            Deluxe Corporation («Deluxe») s'engage à fournir un site Web
            accessible au public le plus large possible, indépendamment de la
            technologie ou de la capacité. Nous travaillons activement à
            accroître l'accessibilité et la convivialité de notre site Web.
          </p>

          <p>
            Ce site Web s'efforce de se conformer au niveau AA du World Wide Web
            Consortium (W3C) Web Content Accessibility Guidelines 2.1. Ces
            lignes directrices expliquent comment rendre le contenu Web plus
            accessible aux personnes handicapées. Le respect de ces directives
            contribuera à rendre le Web plus convivial pour tous.
          </p>

          <p>
            Bien que nous nous efforcions de respecter les directives et les
            normes acceptées en matière d'accessibilité et de convivialité, il
            n'est pas toujours possible de le faire dans toutes les zones du
            site Web.
          </p>

          <p>
            Si vous avez des questions ou des préoccupations spécifiques
            concernant l'accessibilité de ce site ou avez besoin d'aide pour
            utiliser ce site, veuillez nous contacter. Lorsque vous nous
            contactez, veuillez spécifier la page Web sur laquelle vous avez une
            question, souhaitez soumettre un commentaire ou avez besoin d'aide.
          </p>

          <p>
            Veuillez envoyer un e-mail à{" "}
            <a href="mailto:accessibilité@deluxe.com">
              accessibilité@deluxe.com
            </a>{" "}
            ou appeler:
          </p>

          <p>
            877-632-7758 - Produits promotionnels, emballage de vente au détail
            <br />
            866-749-6327 - Chèques d'affaires et formulaires
          </p>
        </div>
      </div>
    </div>
  );
}
