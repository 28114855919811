import React from "react";
import Layout from "../other/layout";
import Politique from "../components/AC-StaticPages/Politique";
import SEO from "../other/seo";

export default function PolitiquePage() {
  return (
    <Layout>
      <SEO title="Politique d'accessibilité du site Web" />

      <Politique />
    </Layout>
  );
}
